<template>
  <div>
    <b-button v-b-toggle.sidebar-right>Menu</b-button>
    <b-sidebar id="sidebar-right" title="Admin" right shadow>
      <div class="p-3 text-center">
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item @click="path('/resgate')">
              Solicitações de resgate
            </b-nav-item>

            <b-nav-item @click="path('/baixa-pagamento')">
              Baixa de pagamento</b-nav-item>

            <b-nav-item @click="path('/segurados')">Segurados</b-nav-item>
            <b-nav-item @click="path('/cobrancas')">Cobranças</b-nav-item>
            <b-nav-item @click="path('/analise-vendas')">Checkout Vendas</b-nav-item>
            <b-nav-item @click="path('/analise-cobrancas')">Analise Cobranças</b-nav-item>
            <b-nav-item @click="path('/suporteEssor')">Suporte essor</b-nav-item>
             <b-nav-item @click="path('/indicacao')">Indicações</b-nav-item>
             <b-nav-item @click="path('/encurtador')">Encurtador</b-nav-item>
            <b-nav-item @click="path('/liberar-email')">Liberar e-mail</b-nav-item>
            <b-nav-item @click="path('/retencao')">Retenção</b-nav-item>
            <b-nav-item @click="path('/beneficios')">Beneficios do Registro</b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import auth from "../../auth";
export default {
  components: {},
  data() {
    return {};
  },
  created() { },
  methods: {
    path(route) {
      this.$router.push(route, () => { });
    },
    exit() {
      auth.logout();
      this.$router.push("/", () => { });
    },
  },
};
</script>
